import React from 'react';
import PropTypes from 'prop-types';

import icons from 'core/icons.js';

export default class Icon extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    render() {
        const {name} = this.props;
        const icon = icons[name];
        if (!icon) {
            throw new Error(`Missing Icon: ${name}`);
        }
        return icon;
    }
}
