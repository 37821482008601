import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';
import React from 'react';

import analytics, {trackEvent} from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import query from 'query.js';

import Icon from 'core/components/Icon';
import LocationInfo from 'core/components/LocationInfo.js';

import {
    NEARBY_HOSPITAL_CONTACT_TYPE,
    HOSPITAL_CONTACT_TYPE,
    PROFILE_CONTACT_TYPE,
    PRACTICE_GRP_CONTACT_TYPE,
    PRACTICE_LOC_CONTACT_TYPE,
} from 'core/constants.js';

export default class ContactModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        contactId: PropTypes.number.isRequired,
        contactName: PropTypes.string.isRequired,
        contactType: PropTypes.oneOf([
            NEARBY_HOSPITAL_CONTACT_TYPE,
            HOSPITAL_CONTACT_TYPE,
            PROFILE_CONTACT_TYPE,
            PRACTICE_GRP_CONTACT_TYPE,
            PRACTICE_LOC_CONTACT_TYPE,
        ]),
        isEnhanced: PropTypes.bool,
        items: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                id: PropTypes.number.isRequired,
                localityName: PropTypes.string,
                localityState: PropTypes.string,
                name: PropTypes.string.isRequired,
                phones: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ),
                practiceUrl: PropTypes.string,
                type: PropTypes.oneOf([
                    NEARBY_HOSPITAL_CONTACT_TYPE,
                    HOSPITAL_CONTACT_TYPE,
                    PROFILE_CONTACT_TYPE,
                    PRACTICE_GRP_CONTACT_TYPE,
                    PRACTICE_LOC_CONTACT_TYPE,
                ]),
            }).isRequired,
        ).isRequired,
        location: PropTypes.string,
        phoneNumber: PropTypes.string,
        selectedItem: PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.oneOf([
                NEARBY_HOSPITAL_CONTACT_TYPE,
                HOSPITAL_CONTACT_TYPE,
                PROFILE_CONTACT_TYPE,
                PRACTICE_GRP_CONTACT_TYPE,
                PRACTICE_LOC_CONTACT_TYPE,
            ]).isRequired,
        }),
    };

    static defaultProps = {
        items: [],
    };

    constructor(props) {
        super(props);

        const {items, phoneNumber, selectedItem} = props;
        let activeItem = items[0];
        if (selectedItem) {
            activeItem = this.findActiveItem(
                `${selectedItem.type}-${selectedItem.id}`,
            );
        }
        this.state = {
            activeItem,
            displayPhone: phoneNumber
                ? phoneNumber
                : activeItem.phones[0].number,
            practiceChanged: false,
            eventId: null,
        };
    }

    async componentDidMount() {
        const {activeItem, displayPhone} = this.state;
        const eventId = await this.trackPhone(activeItem, displayPhone);
        this.setState({eventId});
    }

    async trackPhone(activeItem, displayPhone, eventId) {
        let eventName;
        let trackData;
        const {contactId} = this.props;
        switch (activeItem.type) {
            case NEARBY_HOSPITAL_CONTACT_TYPE:
                return null;
            case HOSPITAL_CONTACT_TYPE:
                return trackEvent('/api/hospitals/v1/events/phone-clicks/', {
                    hospital: activeItem.id,
                    phone: displayPhone,
                });
            case PRACTICE_GRP_CONTACT_TYPE:
                eventName = 'practiceGroupPhoneClick';
                trackData = {
                    'practiceGroupId': activeItem.id,
                    'phoneNumber': displayPhone,
                };
                break;
            case PRACTICE_LOC_CONTACT_TYPE:
                eventName = 'practiceLocationPhoneClick';
                trackData = {
                    'practiceLocationId': activeItem.id,
                    'phoneNumber': displayPhone,
                };
                break;
            case PROFILE_CONTACT_TYPE:
                eventName = 'practitionerPhoneClick';
                trackData = {
                    'health_fund': query.parse().health_fund,
                    'practitioner': contactId,
                    'practice_position': activeItem.id,
                    'phone_number': displayPhone,
                };
                break;
            default:
                throw new Error('Invalid type for tracking phone clicks');
        }
        return analytics.track(eventName, trackData, eventId);
    }

    @autobind
    async updateSelectedItem(event) {
        const selectedKey = event.target.value;
        const activeItem = this.findActiveItem(selectedKey);
        const updateEvent = activeItem.type === this.state.activeItem.type;
        const displayPhone = activeItem.phones[0].number;
        this.setState({
            activeItem,
            displayPhone,
            practiceChanged: true,
        });
        const eventId = await this.trackPhone(
            activeItem,
            displayPhone,
            updateEvent ? this.state.eventId : undefined,
        );
        this.setState({eventId});
    }

    // Find a matching item given a key or return first one if couldn't find anything
    findActiveItem(key) {
        return (
            this.props.items.find((item) => this.getKey(item) === key) ||
            this.props.items[0]
        );
    }

    getKey(item) {
        return `${item.type}-${item.id}`;
    }

    renderDropDownMenu() {
        const {items, contactType} = this.props;
        return (
            <form>
                <select
                    onChange={this.updateSelectedItem}
                    value={this.getKey(this.state.activeItem)}
                >
                    {items.map((item) => {
                        const title =
                            item.localityName && item.localityState
                                ? `${item.localityName}, ${item.localityState}`
                                : item.name;
                        const key = this.getKey(item);
                        return (
                            <option key={key} value={key}>
                                {title}
                            </option>
                        );
                    })}
                </select>
            </form>
        );
    }

    renderPhoneModal() {
        const {
            closeModal,
            contactName,
            isEnhanced,
            items,
            location,
        } = this.props;
        const {displayPhone} = this.state;
        const {address, name, practiceUrl} = this.state.activeItem;
        const telNumber = displayPhone.replace(/\s/g, '');

        let PromotionsMessage;
        if (location === PROFILE_CONTACT_TYPE) {
            PromotionsMessage = require('promotions/components/PromotionsMessage.js')
                .default;
        }
        return (
            <>
                <button className="dismiss" onClick={closeModal} type="button">
                    <Icon name="close" />
                </button>
                <h1>{`Contact ${contactName}`}</h1>
                <b>
                    <a href={`tel:${telNumber}`}>{displayPhone}</a>
                </b>
                {items.length > 1 && this.renderDropDownMenu()}
                {address && (
                    <div className="location">
                        <LocationInfo
                            address={address}
                            practiceName={name}
                            practiceUrl={practiceUrl}
                            staticUrl="/static/"
                        />
                    </div>
                )}
                <p>
                    {"Don't forget to mention you found "}
                    <strong>{contactName}</strong>
                    {' on HealthShare. '}
                    {isEnhanced && (
                        <span>
                            {'As they are a '}
                            <strong>{'HealthShare partner'}</strong>
                            {
                                ', you may be able to find a more suitable appointment.'
                            }
                        </span>
                    )}
                </p>
                {PromotionsMessage && <PromotionsMessage />}
            </>
        );
    }

    renderHospitalPhoneModal() {
        const {closeModal, contactName, phoneNumber} = this.props;
        const {street1, locality, name} = this.props.items[0];
        const address = `${street1}, ${locality?.name}, ${locality?.state?.name}, ${locality?.post_code}`;

        return (
            <>
                <button className="dismiss" onClick={closeModal} type="button">
                    <Icon name="close" />
                </button>
                <h1>{`Contact ${contactName}`}</h1>
                <b>
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </b>

                <div className="location">
                    <LocationInfo
                        address={address}
                        practiceName={name}
                        staticUrl="/static/"
                    />
                </div>
                <p>
                    {"Don't forget to mention you found "}
                    <strong>{contactName}</strong>
                    {' on HealthShare. '}
                </p>
            </>
        );
    }

    render() {
        const {isHospitalDirectory} = this.props;
        return isHospitalDirectory
            ? this.renderHospitalPhoneModal()
            : this.renderPhoneModal();
    }
}
