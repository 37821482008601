import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';

import Button from 'core/components/Button.js';
import {AnalyticsStoreContext} from 'core/stores/RootStore.js';

import {Link} from '@HealthShareAU/hs-component-library';
export default class CollapsibleNumber extends React.Component {
    static contextType = AnalyticsStoreContext;

    static propTypes = {
        analyticsEventData: PropTypes.shape({
            eventName: PropTypes.string,
            eventUrl: PropTypes.string,
            data: PropTypes.shape({
                [PropTypes.string]: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }).isRequired,
        }),
        customClass: PropTypes.string,
        number: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        title: PropTypes.string,
        type: PropTypes.string,
    };

    static defaultProps = {
        customClass: '',
        type: 'phone',
    };

    constructor(props) {
        super(props);

        this.state = {
            showFullNum: false,
        };
    }

    @autobind
    displayFullNum() {
        const {analyticsEventData, number, onClick} = this.props;
        if (onClick) {
            onClick();
        }
        this.trackPhoneClick();
        this.setState({showFullNum: true});
    }

    @autobind
    trackPhoneClick() {
        const {analyticsEventData, number} = this.props;
        if (!analyticsEventData) {
            return;
        }
        const {eventName, eventUrl, data} = analyticsEventData;
        data.phoneNumber = number;
        if (eventUrl) {
            this.context.genericTrackEvent(eventUrl, data);
        } else {
            this.context.trackEvent(eventName, data);
        }
    }

    getTitle() {
        const {title, type} = this.props;
        return title ? title : `Click to show ${type} number`;
    }

    renderText() {
        return (
            <>
                <Link text={`Show ${this.props.type} number`} />
            </>
        );
    }

    render() {
        const {
            props: {number},
            state: {showFullNum},
        } = this;

        const numberParts = number.split(' ');
        const truncatedNumber = numberParts
            .slice(0, numberParts.length - 1)
            .join(' ');

        if (showFullNum || !truncatedNumber) {
            return <a href={`tel:${number}`}>{number}</a>;
        }

        return (
            <>
                {`${truncatedNumber}\u2026\u00A0`}
                <Button
                    action={this.displayFullNum}
                    customClass={`button-handle collapsible-text ${this.props.customClass}`.trim()}
                    text={this.renderText()}
                    title={this.getTitle()}
                />
            </>
        );
    }
}
