import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import Link from 'core/components/Link.js';
import query from 'query.js';

export default class LinkWithProperties extends React.Component {
    static propTypes = {
        hashParams: PropTypes.string,
        href: PropTypes.string.isRequired,
        searchParams: PropTypes.shape({
            [PropTypes.string]: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    };

    @autobind
    getUrl() {
        const {hashParams, href, searchParams} = this.props;
        let url = href;
        if (searchParams) {
            url += query.buildQueryString(searchParams, {fullPath: false});
        }
        if (hashParams) {
            url += `#${hashParams}`;
        }
        return url;
    }
    render() {
        return <Link {...this.props} href={this.getUrl()} />;
    }
}
