import React from 'react';
import PropTypes from 'prop-types';

import events from 'events.js';
import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import {ENTER} from 'core/constants.js';
import {Link as LinkLibrary} from '@HealthShareAU/hs-component-library';

export default class Link extends React.Component {
    static propTypes = {
        action: PropTypes.func,
        actionWithEvent: PropTypes.func,
        children: PropTypes.node,
        controls: PropTypes.string,
        customClass: PropTypes.string,
        data: PropTypes.objectOf(PropTypes.string),
        dataTestId: PropTypes.string,
        disabled: PropTypes.bool,
        expandText: PropTypes.string,
        gaObjectType: PropTypes.string,
        gaSelector: PropTypes.string,
        hasConcatenation: PropTypes.bool,
        href: PropTypes.string,
        icon: PropTypes.string,
        isExternalLink: PropTypes.bool,
        label: PropTypes.string,
        rel: PropTypes.string,
        role: PropTypes.string,
        selected: PropTypes.bool,
        target: PropTypes.bool,
        text: PropTypes.string,
        title: PropTypes.string,
        useComponentLib: PropTypes.bool,
    };

    static defaultProps = {
        customClass: '',
        isExternalLink: false,
    };

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }
    componentDidMount() {
        events.listen(this.ref.current, 'click', this.handleClick);
        events.listen(this.ref.current, 'mousedown', this.handleMouseDown);
        events.listen(this.ref.current, 'keydown', this.handleKeydown);
    }

    componentWillUnmount() {
        events.unlisten(this.ref.current, 'click', this.handleClick);
        events.unlisten(this.ref.current, 'mousedown', this.handleMouseDown);
        events.unlisten(this.ref.current, 'keydown', this.handleKeydown);
    }

    @autobind
    handleClick(event) {
        const {action, actionWithEvent} = this.props;
        if (actionWithEvent) {
            actionWithEvent(event);
        } else if (action) {
            action();
        }
    }

    @autobind
    handleKeydown(event) {
        if (event.key === ENTER) {
            this.handleClick(event);
        }
    }

    computeRelAttribute() {
        const {isExternalLink, rel} = this.props;

        let relAttr = rel ? rel : '';
        if (isExternalLink) {
            if (relAttr) {
                relAttr += ' ';
            }
            relAttr += 'noopener noreferrer';
        }

        return relAttr;
    }

    renderIcon() {
        const {icon} = this.props;
        if (icon) {
            return <Icon name={icon} />;
        }
        return null;
    }

    render() {
        const {
            customClass,
            children,
            controls,
            data,
            href,
            gaObjectType,
            gaSelector,
            id,
            isExternalLink,
            label,
            role,
            selected,
            title,
        } = this.props;

        //props for LinkLibrary
        const {
            useComponentLib,
            dataTestId,
            disabled,
            expandText,
            hasConcatenation,
            iconLeft,
            iconRight,
            onBlur,
            onClick,
            onConcatenation,
            onFocus,
            onKeyDown,
            onMouseDown,
            size,
            target,
            text,
        } = this.props;

        const relAttr = this.computeRelAttribute();
        const dataProps = {};
        if (useComponentLib) {
            return (
                <LinkLibrary
                    customClass={customClass}
                    dataTestId={dataTestId}
                    disabled={disabled}
                    expandText={expandText}
                    gaObjectType={gaObjectType}
                    gaSelector={gaSelector}
                    hasConcatenation={hasConcatenation}
                    href={href}
                    iconLeft={iconLeft}
                    iconRight={iconRight}
                    onBlur={onBlur}
                    onClick={onClick}
                    onConcatenation={onConcatenation}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    onMouseDown={onMouseDown}
                    size={size}
                    target={target}
                    text={text}
                />
            );
        }
        if (data) {
            const key = Object.keys(data)[0];
            const val = data[key];
            dataProps[`data-${key}`] = val;
        }
        return (
            <a
                aria-controls={controls}
                aria-label={label ? label : null}
                aria-selected={selected}
                className={customClass.trim()}
                data-ga-object-type={gaObjectType}
                data-ga-selector={gaSelector}
                href={href}
                id={id}
                ref={this.ref}
                rel={relAttr ? relAttr : null}
                role={role}
                tabIndex={href ? undefined : '0'}
                target={isExternalLink ? '_blank' : null}
                title={title}
                {...dataProps}
            >
                {this.renderIcon()}
                {children}
            </a>
        );
    }
}
