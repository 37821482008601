import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import events from 'events.js';

export default class ImageGalleryModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                thumbnailUrl: PropTypes.string.isRequired,
                fullSizeUrl: PropTypes.string.isRequired,
            }),
        ),
        selectedIndex: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: props.selectedIndex,
        };
        this.images = props.images;
    }

    componentDidMount() {
        events.listen(window, 'keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        events.unlisten(window, 'keydown', this.handleKeyDown);
    }

    @autobind
    nextImage() {
        this.setState((prevState) => {
            {
                const prevIndex = prevState.currentIndex;
                return {
                    currentIndex: (prevIndex + 1) % this.images.length,
                };
            }
        });
    }

    @autobind
    prevImage() {
        this.setState((prevState) => {
            {
                let newIndex = prevState.currentIndex - 1;
                if (newIndex < 0) {
                    newIndex = this.images.length - 1;
                }
                return {
                    currentIndex: newIndex,
                };
            }
        });
    }

    @autobind
    handleKeyDown(event) {
        switch (event.key) {
            case 'ArrowRight':
                this.nextImage();
                break;
            case 'ArrowLeft':
                this.prevImage();
                break;
        }
    }

    render() {
        const {closeModal} = this.props;
        const {currentIndex} = this.state;
        const imgSrc = this.images[currentIndex].fullSizeUrl;
        return (
            <>
                <button className="dismiss" onClick={closeModal} type="button">
                    <Icon name="close" />
                </button>
                <figure className="lightbox">
                    <img src={imgSrc} />
                    {this.images.length > 1 && (
                        <nav>
                            <button
                                className="previous"
                                onClick={this.prevImage}
                            >
                                <Icon name="arrowInverse" />
                            </button>
                            <button className="next" onClick={this.nextImage}>
                                <Icon name="arrow" />
                            </button>
                        </nav>
                    )}
                </figure>
            </>
        );
    }
}
